<template>
  <i
      :class="getClasses()"
      :style="IconSize"
      aria-hidden="true"
      @click.stop="$emit('click')"
  >
    <slot></slot>
  </i>
</template>

<script>
const prefix = "_icon-"
const SIZE_MAP = {
  xSmall: '12px',
  small: '16px',
  default: '30px',
  medium: '24px',
  large: '36px',
  xLarge: '40px'
}
export default {
  name: "VIcon",
  props: {
    icon: {
      type: String,
      default: null,
    },
    size: String,
    hovering: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     * @return {string}
     */
    IconSize() {
      return `font-size: ${this.getSize()}`
    }
  },
  methods: {
    upperFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    getIcon() {
      let iconName = ''
      if (!this.icon) {
        if (this.$slots.default) {
          iconName = `${prefix}${this.upperFirst(
              this.$slots.default[0].text?.trim()
          )}`;
          this.$slots.default[0].text = ""
        } else {
          iconName = `${prefix}${this.this.icon.trim()}`
        }
      }
      return iconName
    },
    getClasses() {
      let clases = [];
      clases.push("v-icon");
      clases.push(this.getIcon())
      this.hovering ? clases.push("hovering") : "";
      return clases
    },
    getSize() {
      const explicitSize = Object.keys(SIZE_MAP).find(key => this.size === key)

      return !this.size || this.size.length === 0 ? SIZE_MAP.default : ((explicitSize && SIZE_MAP[explicitSize]) || this.convertToUnit(this.size))
    },
    convertToUnit(str, unit = 'px') {
      if (str == null || str === '') {
        return undefined
      } else if (isNaN(+str)) {
        return String(str)
      } else {
        return `${Number(str)}${unit}`
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-icon {
  align-items: center;
  display: inline-flex;
  font-feature-settings: "liga";
  font-size: 24px;
  justify-content: center;
  letter-spacing: normal;
  line-height: 1;
  position: relative;
  text-indent: 0;
  transition: color 300ms cubic-bezier(.25, .8, .5, 1), visibility 0s;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -2px;
    transition: all 300ms ease-in-out;
  }

  &.isBell {
    position: relative;

    &:after {

      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $RWM-red;

    }
  }

  @media screen and (min-width: 1024px) {
    &.hovering {
      &:hover {
        cursor: pointer;
        color: $RWM-red;
      }
    }
  }
}
</style>
